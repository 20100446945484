<template>
  <div
    :class="['infobox', size && `infobox-size-${size}`, `infobox-type-${type}`]"
  >
    <div v-if="title" class="infobox-head">
      <SvgIcon icon="info" class="infobox-icon" aria-hidden="true" />
      <span class="infobox-title">
        {{ title }}
      </span>
    </div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="text" class="infobox-text" v-html="text" />
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: null,
      },
      text: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: "info",
        validator: (value) => {
          return ["info", "warning"].includes(value)
        },
      },
      size: {
        type: String,
        default: null,
        validator: (value) => {
          return ["small", "default"].includes(value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .infobox {
    border-radius: $border-radius;
    margin-bottom: $gutter;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    padding: $space-s;
    &-size-small {
      padding: calc($space-s / 2) $space-s;
    }

    &-type-info {
      color: $blue-text;
      background: $blue-tint;
    }

    &-type-warning {
      color: $yellow-text;
      background: $yellow-tint;
    }
  }

  .infobox-head {
    display: flex;
    grid-gap: $base-spacing * 2;
    align-items: center;
  }

  .infobox-title {
    @apply h5;
  }
  .infobox-text {
    margin: 0;
    @apply text-sm leading-normal;
    &:not(:first-child) {
      margin-top: 0.5em;
    }
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  .infobox-icon {
    flex-shrink: 0;
    color: currentColor;
    width: $base-spacing * 6;
    height: $base-spacing * 6;
  }
</style>
